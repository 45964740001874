import React, { FC, useEffect, useRef, useState } from 'react';
import GlobalButton from '../../components/global/global-button/global-button';
import { getLangFullPathname } from '../../components/global/languages/languageUtils';
import RTLayout from '../../components/global/layout/layout';
import SEO from '../../components/global/seo/seo';
import useSSRLayoutEffect from '../../hooks/useSSRLayoutEffect';
import { Guideline } from '../../interfaces/guideline.interface';
import { isClient } from '../../utils';
import * as styles from './guideline.module.scss';

type RTGuidelineProps = {
  pageContext: Guideline;
};

const RTGuideline: FC<RTGuidelineProps> = ({ pageContext }) => {
  const [currentLang, setCurrentLang] = useState('');
  const game = pageContext;
  const guidelineTexts = useRef<HTMLDivElement>(null);

  const setTitleColor = () => {
    if (guidelineTexts && guidelineTexts.current) {
      guidelineTexts?.current.style.setProperty('--title-color', game.mainColor);
    }
  };

  useSSRLayoutEffect(() => {
    const currentLang = localStorage.getItem('RT_current_lang');

    if (currentLang) {
      setCurrentLang((JSON.parse(currentLang) as { value: string }).value);
    }
  }, []);

  useEffect(() => {
    setTitleColor();
  }, [game]);

  return (
    <RTLayout
      menuText={pageContext?.uiElements?.menuTxt[0]}
      footerText={pageContext?.uiElements?.footerTxt[0]}
      langModalTranslations={{ title: pageContext?.uiElements?.languageModalTitle, subtitle: pageContext?.uiElements?.languageModalText }}
      cookie={{
        cookieContent: pageContext?.uiElements.cookieContent,
        cookieButtonAccept: pageContext?.uiElements.cookieButtonAccept,
        cookieButtonReject: pageContext?.uiElements.cookieButtonReject,
      }}
    >
      <SEO title={`${game.title} | Guidelines`} />
      <div
        className='text-white bg-dark-blue bg-cover bg-fixed bg-no-repeat bg-center'
        style={
          game.guidelinesBackgroundImage && game.guidelinesBackgroundImage[0]
            ? {
                backgroundImage: `url('${game.guidelinesBackgroundImage[0].url}')`,
              }
            : {}
        }
      >
        <section className='text-white relative z-10'>
          <div className='flex absolute inset-0 overflow-hidden'>
            <div
              className={`w-full ${styles.heroLeftContainer}`}
              style={game.guidelinesLeftColor ? { backgroundColor: game.guidelinesLeftColor } : {}}
            >
              {game.guidelinesLeftImage && game.guidelinesLeftImage[0] && (
                <div className={styles.heroBackgroundImage}>
                  <img src={game.guidelinesLeftImage[0].url} />
                </div>
              )}
            </div>
            <div className={`bg-black hidden lg:block absolute z-10 top-0 bottom-0 h-full right-0 lg:w-4/12 ${styles.imageWrapper}`}>
              <div className={`opacity-60 h-full`}>
                {game.guidelinesRightImage && game.guidelinesRightImage[0] && (
                  <img src={game.guidelinesRightImage[0].url} alt={game.title} className='object-cover h-full w-full' />
                )}
              </div>
            </div>
          </div>
          <div className='container relative'>
            <div className='relative z-20 pt-52 pb-40 lg:pb-36 lg:flex lg:justify-between lg:items-center space-y-10 lg:space-y-0'>
              <div className='space-y-10'>
                <h1 className='relative text-3xl md:text-5xl font-bold inline-block'>
                  {game.title}
                  <span className={styles.heroTitleBar} style={{ backgroundColor: game.mainColor }} />
                </h1>
                <h2 className='text-xl md:text-3xl'>{pageContext?.uiElements?.competitionGuidelineTxt[0].title}</h2>
              </div>
              <GlobalButton
                url={getLangFullPathname(currentLang, `/tournament-form?game=${pageContext.gameCodeName}`, true, currentLang !== '')}
                text={pageContext?.uiElements?.buttonsTxt[0].licenceRequest}
                bgColor={game.mainColor}
                txtColor='#fff'
              />
            </div>
          </div>
        </section>

        <section className='mt-8 md:mt-12'>
          <div className='container'>
            <div className='max-w-6xl mx-auto py-8 md:py-12'>
              <div
                ref={guidelineTexts}
                className='guideline-text wysiwyg'
                dangerouslySetInnerHTML={{
                  __html: game.guideline,
                }}
              />
              <div
                className={`${styles.pdfButtonContainer} bg-dark-blue`}
                style={
                  isClient && window.innerWidth < 768
                    ? {
                        backgroundImage: `url('${
                          game.guidelinesBackgroundImage && game.guidelinesBackgroundImage[0] ? game.guidelinesBackgroundImage[0].url : null
                        }')`,
                      }
                    : { backgroundColor: `transparent` }
                }
              >
                {pageContext.guidelinesPdf && pageContext.guidelinesPdf.length !== 0 && (
                  <GlobalButton
                    url={pageContext.guidelinesPdf[0].url}
                    target='_blank'
                    text={pageContext?.uiElements?.competitionGuidelineTxt[0].downloadButtonText}
                    bgColor={game.mainColor}
                    txtColor='#fff'
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </RTLayout>
  );
};

export default RTGuideline;
