import { Link } from 'gatsby';
import React, { FC, useRef, useState } from 'react';
import * as styles from './global-button.module.scss';
import './global-button.scss';

type ButtonProps = {
  url?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  text: string;
  bgColor?: string;
  txtColor?: string;
  animated?: boolean;
};

const GlobalButton: FC<ButtonProps> = ({ url, text, bgColor = '#000', txtColor = '#fff', target = '_self', animated }: ButtonProps) => {
  const button = useRef<HTMLButtonElement>(null);
  const [hovered, setHovered] = useState(false);

  const mouseover = () => setHovered(true);
  const mouseout = () => setHovered(false);

  if (url) {
    return (
      <Link to={url} target={target} className='inline-block'>
        <button
          onMouseOver={mouseover}
          onMouseOut={mouseout}
          ref={button}
          className={`${styles.globalButton} ${hovered ? 'is-hovered' : ''}`}
          style={{
            opacity: animated ? '0' : '1',
            transform: animated ? 'translateY(30px)' : 'translateY(0)',
            color: hovered ? bgColor : txtColor,
            backgroundColor: bgColor,
          }}
        >
          <span>
            <span className='slash'>/ </span>
            {text}
          </span>
        </button>
      </Link>
    );
  } else {
    return (
      <button
        onMouseOver={mouseover}
        onMouseOut={mouseout}
        ref={button}
        className={`${styles.globalButton} ${hovered ? 'is-hovered' : ''}`}
        style={{ color: hovered ? bgColor : txtColor, backgroundColor: bgColor }}
      >
        <span>
          <span className='slash'>/ </span>
          {text}
        </span>
      </button>
    );
  }
};

export default GlobalButton;
